<template>
  <top-header-menu-wrapper menu-class="pale-green icon-hea1">
    <template v-slot:left>
      <home-button />
    </template>
    <div class="component-title">
      <slot></slot>
    </div>
  </top-header-menu-wrapper>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
export default {
  name: "CalendarHeaderMenu",
  components: {
    TopHeaderMenuWrapper
  }
};
</script>
