import MobileScreen from "@/layouts/MobileScreen";
import CalendarHeaderMenu from "@/components/plans/CalendarHeaderMenu";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
export default {
  components: {
    MobileScreen,
    CalendarHeaderMenu,
    PlansFooterMenu
  }
};
